<template>
  <div
    :id="`${type}-${id}`"
    v-click-outside="onClickOutside"
    class="position-relative d-flex flex-column align-items-end justify-content-end"
  >
    <b-input-group
      ref="inputGroup"
      size="sm"
      style="min-width:180px"
    >
      <b-form-input
        ref="query"
        v-model="query"
        type="search"
        :disabled="disabled || busy"
        :size="size"
        :placeholder="placeholder"
        @input="onQueryChange"
        @focus="onFocus"
        @blur="onBlur"
      />
      <b-input-group-append>
        <b-input-group-text v-if="busy">
          <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
        </b-input-group-text>
        <b-input-group-text v-else>
          <font-awesome-icon :icon="['fas', 'magnifying-glass']" @click="onSearch" />
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
    <b-list-group
      v-if="isReady"
      size="sm"
      class="position-absolute w-100 overflow-auto text-nowrap"
      style="top: 32px; z-index: 3; max-height: 200px; min-width: fit-content; max-width: 100svw;"
      :style="navStyle"
      @scroll="onScroll"
    >
      <b-list-group-item
        v-for="(item, i) in results"
        :key="i"
        size="sm"
        class="cursor-pointer py-1"
        style="min-width: fit-content"
        href="#"
        @click.prevent.stop="onSelect(item)"
      >
        {{ item.text }}
      </b-list-group-item>
    </b-list-group>
    <div
      v-if="multiple && selected && selected.length"
      class="mt-2 d-flex flex-row align-items-end justify-content-end flex-wrap"
    >
      <b-badge
        v-for="(option, i) in selected.filter(i => value.includes(i.value))"
        :key="i"
        variant="info"
        class="m-1"
      >
        {{ option.text }}
        <font-awesome-icon
          v-if="!disabled"
          :icon="['fas', 'xmark']"
          size="sm"
          style="cursor: pointer"
          @click="onRemoveSelected(option)"
        />
      </b-badge>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash/function'
import { mapActions } from 'vuex'
import { mapComputedSearchFields } from '~/store/search'
const id = Math.random().toString(36).slice(2)
export default {
  name: 'AutoComplete',
  props: {
    type: {
      type: String,
      required: true
    },
    idx: {
      type: [String, Number],
      required: true
    },
    value: {
      type: [String, Number, Array, null],
      default: null
    },
    size: {
      type: String,
      default: 'sm'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      id,
      isReady: false,
      isSelected: !!this.value
    }
  },
  computed: {
    ...mapComputedSearchFields(id, [
      'busy',
      'query',
      'selected',
      'results',
      'meta',
      'cursor',
      'page',
      'v'
    ]),
    navStyle () {
      let style = ''
      if (this.$refs.inputGroup) {
        let height = this.$refs.inputGroup.offsetHeight
        if (height === 0) {
          height = 30
        }
        style += `top:${height + 2}px;`
      } else {
        style += 'top:32px;'
      }
      return style
    }
  },
  watch: {
    async value (n, o) {
      if (n && n !== o) {
        this.$store.dispatch('setLoadingEnable', false, { root: true })
        await this.initField({
          id: this.idStr,
          value: this.value
        })
        this.$store.dispatch('setLoadingEnable', true, { root: true })
      } else if (!n) {
        this.$emit('input', [])
      }
    }
  },
  async beforeMount () {
    this.addField({ id: this.idStr, type: this.type })
    if (this.value && this.v === 0) {
      this.$store.dispatch('setLoadingEnable', false, { root: true })
      await this.initField({
        id: this.idStr,
        value: this.value
      })
      this.$store.dispatch('setLoadingEnable', true, { root: true })
    }
    this.$nextTick(() => {
      this.isReady = true
    })
  },
  beforeDestroy () {
    this.removeField({ id: this.idStr, v: this.v })
  },
  mounted () {
    if (this.cursor > 0 && this.$refs.query && this.$refs.query.$el.setSelectionRange) {
      this.$refs.query.$el.focus()
      this.$refs.query.$el.setSelectionRange(this.cursor, this.cursor)
    }
  },
  methods: {
    ...mapActions({
      addField: 'search/add',
      initField: 'search/init',
      removeField: 'search/remove',
      load: 'search/load',
      clear: 'search/clear'
    }),
    async search (q, page = 1) {
      this.$store.dispatch('setLoadingEnable', false, { root: true })
      const column = this.$refs.query
      this.cursor = (column && column.selectionStart) || -1
      await this.load({
        id: this.idStr,
        query: escape(q),
        page
      })
      this.$store.dispatch('setLoadingEnable', true, { root: true })
      if (this.cursor > 0 && this.$refs.query && this.$refs.query.$el.setSelectionRange) {
        this.$refs.query.$el.focus()
        this.$refs.query.$el.setSelectionRange(this.cursor, this.cursor)
      }
    },
    onQueryChange: debounce(function (q) {
      this.isSelected = false
      if (escape(q) !== '') {
        this.search(escape(q))
      } else if (!this.multiple) {
        this.$emit('input', '')
        this.$nextTick(() => {
          // clear field selected
          this.clear({ id: this.idStr })
        })
      }
    }, 500),
    onSelect (item) {
      this.isSelected = true
      this.cursor = -1
      let newValue = this.value
      if (this.multiple) {
        if (!(newValue instanceof Array)) {
          newValue = [newValue]
        }
        newValue = JSON.parse(JSON.stringify(newValue))
        if (!newValue.includes(item.value)) {
          newValue.push(item.value)
        }
      } else {
        newValue = [item.value]
      }
      this.$emit('input', newValue.sort((a, b) => {
        if (a > b) {
          return 1
        }
        if (a < b) {
          return -1
        }
        return 0
      }))
      this.$nextTick(async () => {
        this.$store.dispatch('setLoadingEnable', false, { root: true })
        await this.initField({
          id: this.idStr,
          value: newValue
        })
        this.$store.dispatch('setLoadingEnable', true, { root: true })
      })
    },
    onRemoveSelected (option) {
      const newValue = JSON.parse(JSON.stringify(this.value))
        .filter(i => i !== option.value)
      this.$emit('input', newValue.sort((a, b) => {
        if (a > b) {
          return 1
        }
        if (a < b) {
          return -1
        }
        return 0
      }))
      this.$nextTick(async () => {
        this.$store.dispatch('setLoadingEnable', false, { root: true })
        await this.initField({
          id: this.idStr,
          value: newValue
        })
        this.$store.dispatch('setLoadingEnable', true, { root: true })
      })
    },
    onFocus () {},
    onBlur () {},
    onClickOutside (e) {
      if (this.results && this.results.length) {
        this.results = []
        this.meta = null
        this.page = 0
      }
    },
    onSearch () {
      this.search(escape(this.query))
    },
    onScroll: debounce(function (e) {
      const triggerLimit = 0.5
      const scrollValue = e.target.scrollTop + e.target.offsetHeight
      const height = e.target.scrollHeight
      const nextPage = this.page + 1
      if (scrollValue / height >= triggerLimit && nextPage <= this.meta.lastPage) {
        this.search(escape(this.query), this.page + 1)
      }
    }, 100)
  }
}
</script>

<style scoped>

</style>
